<template>
  <div class="users-list">
    <b-row>
      <b-col>
        <b-card>
          <h2>Пользователи</h2>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'UsersList',
}
</script>
